<template>
  <div class="impot-revenu-update-page page-layout">
    <PageHeader
      title="Modifier un impot revenu"
      :has-back="true"
      @back="goBack"
    />

    <md-card class="meep-form">
      <!--annee-->
      <md-card-content>
        <div class="meep-form-column meep-form-single-column">
          <div class="meep-input">
            <!--montant-->
            <md-field :class="{ 'md-invalid': errors.has('montant') }">
              <label>Montant</label>
              <md-input
                v-model="form.montant"
                v-validate="'required|decimal'"
                name="montant"
                type="montant"
              />
              <span class="md-error" v-show="errors.has('montant')">{{
                errors.first("montant")
              }}</span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('coef') }">
              <label>Coefficiant</label>
              <md-input
                v-model="form.coef"
                v-validate="'required|decimal'"
                name="coef"
                type="coef"
              />
              <span class="md-error" v-show="errors.has('coef')">{{
                errors.first("coef")
              }}</span>
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
              class="md-raised md-primary"
              @click.native="openConfirmDeleteModal"
            >
              Supprimer cet impôt
            </md-button>

            <md-button
              class="md-raised md-primary"
              @click.native="updateImpotRevenu"
            >
              Valider
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :objectToActUpon="form"
      @close="closeConfirmDeleteModal"
      @confirm="removeRevenu"
    />
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import confirmActionModal from "../../../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,
      form: {
        montant: "",
        coef: "",
      },
      isConfirmDeleteModalOpen: false,
      confirmDeleteModalText: {
        header: "Suppression d'un impôt",
        body() {
          return "Vous êtes sur le point de supprimer l'impôt sur le revenu";
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    calculatorsModel
      .getRevenu(to.params.id)
      .then(formData => {
        next(vm => {
          vm.form = formData;
          if (vm.isDebug) {
            console.log("impotRevenu with id" + to.params.id + ":");
            console.log(vm.form);
          }
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
  },
  methods: {
    goBack() {
      window.history.back();
    },
    updateImpotRevenu() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .updateRevenu(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "L'impot a bien été modifié",
              });
              this.$router.push("/admin/calculators/impot-revenu/list");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    removeRevenu() {
      calculatorsModel
        .removeRevenu(this.form.id)
        .then(() => {
          this.$toasted.global.AppSucces({
            message: "L'impot a suprimé",
          });
          this.$router.push("/admin/calculators/impot-revenu/list");
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    openConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.impot-revenu-update-page {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin: 32px auto;
  }
}
</style>
